@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Open Sans", sans-serif;
}

.home {
  width: 100%;
  overflow-x: hidden;
}

.slick-slider.slick-slider.bg-darkgray.p-2.slick-initialized {
  padding: 10px;
}

/* .hero,
.why-us {
  background-image: url("./images/assets/home/desktop/bg-pattern-hero-home.svg");
  background-size: cover;
}

.about-page {
  clip-path: polygon(25% 0%, 100% 1%, 100% 100%, 25% 100%, 0% 50%);
  background-image: url("./images/assets/home/desktop/bg-pattern-hero-home.svg");
  background-size: cover;
}

.about-home {
  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
} */

/* .contact-image {
  background-image: url("./images/contact-image.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 50vh;
  border-radius: 20px;
} */

.slick-slider {
  width: 100%;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slick-image img {
  width: 90%;
}

@media (max-width: 767px) {
  .contact-image {
    height: 30vh;
  }
}
